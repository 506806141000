const htmlEl = (typeof document !== 'undefined')
	? document.getElementsByTagName('html')[0]
	: null;

export function setMenuOpenClasses(shadowed: boolean): void {
	if (!htmlEl) return;
	setTimeout(() => {
		htmlEl.classList.add('menu-open');
		if (!shadowed) return;
		document.body.classList.add('shadowed');
	}, 25);
}

export function setModalOpenClasses(shadowed: boolean): void {
	if (!htmlEl) return;
	setTimeout(() => {
		htmlEl.classList.add('modal-open');
		if (!shadowed) return;
		document.body.classList.add('shadowed');
	}, 25);
}

export function unsetOpenClasses(): void {
	if (!htmlEl) return;
	htmlEl.classList.remove('menu-open');
	htmlEl.classList.remove('modal-open');
	document.body.classList.remove('shadowed');
}

/* toyland date range checking for 2024 */

export function toylandPreview(nowDate: Date): boolean {
	const start = new Date(2024, 8, 20); // September 20
	const end = new Date(2024, 9, 12, 6, 59, 59); // October 12, 06:59:59 AM

	return start <= nowDate && nowDate <= end;
}

export function toylandOpen(nowDate: Date): boolean {
	const start = new Date(2024, 9, 12, 7, 0, 0); // October 12, 07:00:00 AM
	const end = new Date(2024, 9, 19, 23, 59, 59); // October 19 11:59:59 PM

	return start <= nowDate && nowDate <= end;
}

export function toylandShop(nowDate: Date): boolean {
	const start = new Date(2024, 9, 20); // October 20
	const end = new Date(2024, 11, 26); // December 26

	return start <= nowDate && nowDate <= end;
}
